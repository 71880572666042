
































































import mixins from 'vue-typed-mixins'
import {
  grammarStore,
  snackbarStore,
} from '@/store'
import GrammarCreateEditFields from '@/components/Grammar/CreateEditFields.vue'
import {
  Grammar,
} from '@/types/grammar'
import {
  Id,
} from '@/types/base'
import {
  newGrammar,
} from '@/util/grammar'
import {
  form,
} from '@/mixins'
import omit from 'lodash.omit'

export default mixins(form)
  .extend({
    components: {
      GrammarCreateEditFields,
    },
    data: () => ({
      loadingSave: false,
      loadingDelete: false,
      editGrammar: newGrammar(),
    }),
    computed: {
      loadingGet (): boolean {
        return grammarStore.isLoadingGet
      },
      hasChanged (): boolean {
        const oldGrammar = this.$copy(this.grammar)
        const editGrammar = omit(oldGrammar, [
          `id`,
        ])
        return !this.$isEqual<Grammar>(this.editGrammar, editGrammar)
      },
      grammar (): (Grammar & Id) {
        return grammarStore.dataById(this.$route.params.id) ?? {
          id: ``,
          ...newGrammar(),
        }
      },
    },
    watch: {
      grammar: {
        immediate: true,
        handler (grammar: (Grammar & Id)) {
          this.reset(grammar)
        },
      },
    },
    async created () {
      await grammarStore.get(this.$route.params.id)
    },
    methods: {
      async save () {
        if (!this.validateForm()) return
        const editGrammar: (Grammar & Id) = {
          id: this.$route.params.id,
          ...this.$copy(this.editGrammar),
        }
        try {
          this.loadingSave = true
          await grammarStore.update(editGrammar)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Grammar successfully updated`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating grammar`,
          })
        }
        this.loadingSave = false
      },
      async deleteGrammar () {
        if (!confirm(`Are you sure you want to delete "${this.grammar.text}"?`)) return
        try {
          this.loadingDelete = true
          await grammarStore.delete(this.$route.params.id)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Grammar successfully deleted`,
          })
          this.$router.go(-1)
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error deleting grammar`,
          })
        }
        this.loadingDelete = false
      },
      async reset (grammar: (Grammar & Id)) {
        const oldGrammar = this.$copy(grammar)
        const editGrammar = omit(oldGrammar, [
          `id`,
        ])
        this.editGrammar = editGrammar
      },
    },
  })
