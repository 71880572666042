import {
  Grammar, GrammarExample, GrammarRule,
} from '@/types/grammar'

export const newGrammar = (): Grammar => ({
  text: ``,
  translation: ``,
  description: ``,
  rules: [
    newGrammarRule(),
  ],
})

export const newGrammarRule = (): GrammarRule => ({
  description: ``,
  examples: [
    newGrammarExample(),
  ],
  formula: ``,
  stem: ``,
})

export const newGrammarExample = (): GrammarExample => ({
  text: ``,
  translation: ``,
})
