var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"autofocus":!_vm.$route.params.id,"rules":[
      _vm.$global.rules.required() ],"label":"Text","outlined":""},model:{value:(_vm.grammar_.text),callback:function ($$v) {_vm.$set(_vm.grammar_, "text", $$v)},expression:"grammar_.text"}}),_c('v-text-field',{attrs:{"rules":[
      _vm.$global.rules.required() ],"label":"Translation","outlined":""},model:{value:(_vm.grammar_.translation),callback:function ($$v) {_vm.$set(_vm.grammar_, "translation", $$v)},expression:"grammar_.translation"}}),_c('v-text-field',{attrs:{"rules":[
      _vm.$global.rules.required() ],"label":"Description","outlined":""},model:{value:(_vm.grammar_.description),callback:function ($$v) {_vm.$set(_vm.grammar_, "description", $$v)},expression:"grammar_.description"}}),_c('v-data-table',{attrs:{"items":_vm.grammar.rules,"headers":_vm.headers,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var pagination = ref.pagination;
return [_c('tbody',[_vm._l((_vm.grammar.rules.slice((pagination.page - 1) * pagination.itemsPerPage, pagination.page * pagination.itemsPerPage)),function(item,i){return _c('tr',{key:("lessonIds-" + i)},[_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.grammar.rules.length > 1)?_c('v-row',{staticClass:"flex-column flex-grow-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":(pagination.page - 1) * pagination.itemsPerPage + i === 0,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveRuleUp((pagination.page - 1) * pagination.itemsPerPage + i)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"disabled":(pagination.page - 1) * pagination.itemsPerPage + i === _vm.grammar.rules.length - 1,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveRuleDown((pagination.page - 1) * pagination.itemsPerPage + i)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-text-field',{staticClass:"my-1",attrs:{"rules":[
                    _vm.$global.rules.required() ],"label":"Description","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].description),callback:function ($$v) {_vm.$set(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i], "description", $$v)},expression:"grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].description"}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"rules":[
                    _vm.$global.rules.required() ],"label":"Formula","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].formula),callback:function ($$v) {_vm.$set(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i], "formula", $$v)},expression:"grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].formula"}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"rules":[
                    _vm.$global.rules.required() ],"label":"Stem","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].stem),callback:function ($$v) {_vm.$set(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i], "stem", $$v)},expression:"grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].stem"}}),_vm._l((_vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples),function(example,j){return _c('v-row',{key:("example-" + i + "-" + j),class:_vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples.length > 1 ? "" : "ml-8",attrs:{"no-gutters":""}},[(_vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples.length > 1)?_c('v-row',{staticClass:"flex-column flex-grow-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":j === 0,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveExampleUp((pagination.page - 1) * pagination.itemsPerPage + i, j)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"disabled":j === _vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples.length - 1,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveExampleDown((pagination.page - 1) * pagination.itemsPerPage + i, j)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":"","justify":"center"}},[_c('v-text-field',{staticClass:"mb-1",attrs:{"rules":[
                        _vm.$global.rules.required() ],"label":"Example (Korean)","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j].text),callback:function ($$v) {_vm.$set(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j], "text", $$v)},expression:"grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j].text"}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"rules":[
                        _vm.$global.rules.required() ],"label":"Example (Translation)","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},model:{value:(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j].translation),callback:function ($$v) {_vm.$set(_vm.grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j], "translation", $$v)},expression:"grammar_.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples[j].translation"}})],1),(_vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples.length > 1)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.grammar.rules[(pagination.page - 1) * pagination.itemsPerPage + i].examples.splice(j, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)}),_c('v-row',{staticClass:"ml-8",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"mb-1",attrs:{"text":"","block":"","color":"accent"},on:{"click":function($event){_vm.addExample((pagination.page - 1) * pagination.itemsPerPage + i)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add example ")],1)],1)],2),(_vm.grammar.rules.length > 1)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.grammar_.rules.splice((pagination.page - 1) * pagination.itemsPerPage + i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)])}),_c('tr',[_c('td',[_c('v-btn',{attrs:{"text":"","block":"","color":"accent"},on:{"click":function($event){return _vm.addRule()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add more ")],1)],1)])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }