













































































































































































































































import Vue, {
  VueConstructor,
} from 'vue'
import mixins from 'vue-typed-mixins'
import {
  PropValidator,
} from 'vue/types/options'
import {
  newGrammar, newGrammarExample, newGrammarRule,
} from '@/util/grammar'
import {
  DataTableHeader,
} from 'vuetify'
import {
  VuetifyTextField,
} from '@/plugins/vuetify/types'
import {
  Grammar,
} from '@/types/grammar'

export default mixins(Vue as VueConstructor<Vue & {
  $refs: {
    ruleDescriptions: VuetifyTextField[]
  }
}>)
  .extend({
    props: {
      grammar: {
        default: newGrammar(),
        type: Object,
      } as PropValidator<Grammar>,
    },
    data: () => ({
      headers: [
        {
          value: `rules`,
          text: `Rules`,
          sortable: false,
        },
      ] as DataTableHeader[],
      grammar_: newGrammar(),
      options: {
        page: 0,
        itemsPerPage: 10,
      },
    }),
    computed: {
    },
    watch: {
      grammar_: {
        deep: true,
        handler (grammar: Grammar) {
          this.$emit(`update:grammar`, grammar)
        },
      },
      grammar: {
        deep: true,
        immediate: true,
        handler (grammar: Grammar) {
          this.grammar_ = grammar
        },
      },
    },
    methods: {
      async addRule () {
        this.grammar_.rules.push(newGrammarRule())
        this.options.page = Math.ceil(this.grammar_.rules.length / this.options.itemsPerPage)
        await this.$nextTick()
        const ruleDescriptions = this.$refs.ruleDescriptions
        ruleDescriptions[ruleDescriptions.length - 1].focus()
      },
      moveRuleUp (index: number) {
        const {
          rules,
        } = this.grammar
        const rule = rules.splice(index, 1)[0]
        rules.splice(index - 1, 0, rule)
      },
      moveRuleDown (index: number) {
        const {
          rules,
        } = this.grammar
        const rule = rules.splice(index, 1)[0]
        rules.splice(index + 1, 0, rule)
      },
      async addExample (ruleIndex: number) {
        this.grammar_.rules[ruleIndex].examples.push(newGrammarExample())
        await this.$nextTick()
        const ruleDescriptions = this.$refs.ruleDescriptions
        ruleDescriptions[ruleDescriptions.length - 1].focus()
      },
      moveExampleUp (ruleIndex: number, exampleIndex: number) {
        const {
          examples,
        } = this.grammar.rules[ruleIndex]
        const example = examples.splice(exampleIndex, 1)[0]
        examples.splice(exampleIndex - 1, 0, example)
      },
      moveExampleDown (ruleIndex: number, exampleIndex: number) {
        const {
          examples,
        } = this.grammar.rules[ruleIndex]
        const example = examples.splice(exampleIndex, 1)[0]
        examples.splice(exampleIndex + 1, 0, example)
      },
    },
  })
